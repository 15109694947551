import React from "react"
import { Link } from "gatsby"
import { Box, Button, Paper, Typography } from "@material-ui/core"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const PageNotFound = () => {
  return (
    <>
      <SEO title="お探しのページは見つかりませんでした" />
      <Layout>
        <Typography
          align="center"
          component="h1"
          gutterBottom
          variant="h4"
          style={{ marginBottom: "40px" }}
        >
          お探しのページが見つかりませんでした。
        </Typography>

        <Paper>
          <Box p={5}>
            <Typography
              align="left"
              component="p"
              gutterBottom
              variant="body1"
              style={{ marginBottom: "24px" }}
            >
              お探しのページは以下のような理由によりご覧いただくことができません。
            </Typography>

            <Box component="ul" mb={3} px={3} textAlign="left">
              <Typography component="li" gutterBottom variant="body1">
                入力したURLが間違っているため。
              </Typography>
              <Typography component="li" gutterBottom variant="body1">
                該当するURLのページが移転したか、URLが変更されたため。
              </Typography>
              <Typography component="li" gutterBottom variant="body1">
                現在、メンテナンス中のため一時的に表示していないため。
              </Typography>
              <Typography component="li" gutterBottom variant="body1">
                ページが削除されたため。
              </Typography>
            </Box>

            <Typography
              align="left"
              component="p"
              gutterBottom
              variant="body1"
              style={{ marginBottom: "32px" }}
            >
              以下よりHomeに戻って、お探しのページをお探しください。
            </Typography>

            <Link to="/">
              <Button color="primary" size="large" variant="contained">
                Homeに戻る
              </Button>
            </Link>
          </Box>
        </Paper>
      </Layout>
    </>
  )
}

export default PageNotFound
